.App {
  text-align: center;
  background-color: #282c34;
}

.Main-header {
  display: flex;
  min-height: 10vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Body-main {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Socials {
  min-height: 17vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
    color: inherit;
}